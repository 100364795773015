
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IPatient } from '../types';

@Component
export default class CustomerFindDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly childs: Array<IPatient> | undefined;

  @Prop({ default: () => undefined })
  readonly parentId: number | undefined;

  loading = false;
  showAppend = false;
  showExists = false;
  filterName = '';
  filterPhone = '';
  filterCode = '';
  patients: Array<IPatient> = [];

  @Watch('value')
  onShow() {
    if (this.value) {
      this.filterName = '';
      this.showExists = false;
      this.search();
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  changeExists() {
    this.showExists = !this.showExists;
    this.search();
  }

  async search() {
    this.loading = true;
    try {
      if (!this.childs || this.showExists) {
        const response = await $app.post('/api/patients/search', { patientName: this.filterName, patientPhone: this.filterPhone, patientCode: this.filterCode, append: true, itemsPerPage: 20, page: 1, totals: 0 });
        this.patients = response.items;
      } else {
        this.patients = this.childs.filter(p => {
          return !this.filterName || this.filterName.trim() === '' ||
            p.patientFirstName.toUpperCase().startsWith(this.filterName.toUpperCase()) ||
            p.patientLastName.toUpperCase().startsWith(this.filterName.toUpperCase()) ||
            p.patientCode.toUpperCase().startsWith(this.filterName.toUpperCase()) ||
            p.patientPhone.toUpperCase().startsWith(this.filterName.toUpperCase())
        });
      }
    } catch (err) {
      $app.pushError(err);
    }
    this.loading = false;
  }

  append() {
    this.visible = false;
    this.showAppend = true;
  }

  async select(item: IPatient) {
    if (this.showExists) {
      if (this.parentId) {
        await $app.post('/api/patients/' + this.parentId + '/childs/' + item.patientId, {});
        if (this.childs) {
          this.childs.push(item);
        }
        this.showExists = false;
        await this.search();
      }
    } else {
      this.$emit('select', item)
    }
  }

  removeChild(item: IPatient) {
    this.$emit('remove', item)
  }
}
